.admin-referral-console {
    padding: 20px;
}

.referral-program-list {
    list-style-type: none;
    padding: 0;
}

.referral-program-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.status-indicator .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.status-indicator .green {
    background-color: green;
}

.status-indicator .yellow {
    background-color: yellow;
}

.status-indicator .red {
    background-color: red;
}

button {
    background-color: #007bff;
    color: white;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    border-radius: 3px;
}

button:hover {
    background-color: #0056b3;
}
