/* Container for the entire section */
.autocomplete-section {
    position: relative;
    margin-bottom: 20px;
    padding: 10px;
}

/* Label styling */
.autocomplete-section label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 1.1em;
    color: #333;
}

/* Input styling 
.autocomplete-section input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-left: -10px;
    margin-bottom: 10px;
    font-size: 1em;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.autocomplete-section input:focus {
    border-color: #3f51b5;
    box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.2);
}
*/

/* Suggestions list */
.suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 300px;
    overflow-y: auto;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

/* Individual suggestion item */
.suggestions-list li {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    transition: background-color 0.3s;
}

.suggestions-list li:last-child {
    border-bottom: none;
}

.suggestions-list li:hover {
    background-color: #f9f9f9;
}

/* Suggestion image */
.suggestions-list li img {
    width: 40px;
    height: 60px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 4px;
}

/* Suggestion text container */
.suggestions-list li div {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

/* Suggestion title */
.suggestions-list li div span {
    font-size: 0.9em;
    font-weight: bold;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Suggestion year or additional info */
.suggestions-list li div small {
    font-size: 0.8em;
    color: #777;
}

/* Selected item display */
.selected-item {
    display: flex;
    align-items: center;
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Selected item image */
.selected-item img {
    width: 80px;
    height: 120px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 4px;
}

/* Selected item title */
.selected-item h2 {
    font-size: 1.2em;
    color: #333;
    margin: 0;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .suggestions-list li {
        padding: 6px;
    }

    .suggestions-list li img {
        width: 30px;
        height: 45px;
    }

    .suggestions-list li div span {
        font-size: 0.8em;
    }

    .suggestions-list li div small {
        font-size: 0.7em;
    }

    .selected-item img {
        width: 60px;
        height: 90px;
    }

    .selected-item h2 {
        font-size: 1em;
    }
}
